/* eslint-disable jsx-a11y/alt-text */
import React from "react";

const GetInTouch = ({ heading, message, email, icons }) => {
  return (
    <>
      <h2 className="display-4 pb-3 text-center">{heading}</h2>

      <a
        className="schedule"
        href="https://calendly.com/imranulashrafi/coffee"
        role="button"
      >
        <img className="icon-touch" src={icons.calendar} />
        Schedule a Google Meet!
      </a>
      <p className="lead text-center pb-3">
        {message}, <a href={`mailto:${email}`}>{email}</a>.
      </p>
    </>
  );
};

export default GetInTouch;
