const navBar = {
  show: false,
};

const mainBody = {
  gradientColors: "#646464, #646464",
  firstName: "Imranul",
  middleName: "",
  lastName: "Ashrafi",
  message: "Victory loves preparation",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/imranulashrafi",
    },
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/imranulashrafi/",
    },
    {
      image: "fa-facebook",
      url: "https://www.facebook.com/imranulashrafi",
    },
  ],
};

const icons = {
  python: require("../assets/icons/python.svg"),
  flask: require("../assets/icons/flask.svg"),
  javascript: require("../assets/icons/javascript.svg"),
  vue: require("../assets/icons/vue.svg"),
  react: require("../assets/icons/react.svg"),
  pytorch: require("../assets/icons/pytorch.svg"),
  mysql: require("../assets/icons/mysql.svg"),
  git: require("../assets/icons/git.svg"),
  java: require("../assets/icons/java.svg"),
  spring: require("../assets/icons/spring.svg"),
  postman: require("../assets/icons/postman.svg"),
  linux: require("../assets/icons/linux.svg"),
  calendar: require("../assets/icons/calendar.svg"),
};

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/dp.jpeg"),
  imageSize: 450,
  message:
    // eslint-disable-next-line no-multi-str
    "I am a Machine Learning researcher and developer focusing on NLP, MLOps and Backend \
    development. Primarily, I use Python as my daily programming language for implementing \
    exciting project ideas. Besides, I love working with Vue.js for building interactive and \
    feature-rich web applications. In my spare time, I try to familiarize myself with the \
    fast-growing field of ML and spend time with my family.",
  resume: require("../assets/cv.pdf"),
};

const repos = {
  show: true,
  heading: "Recent Projects",
  gitHubUsername: "imranulashrafi", //i.e."johnDoe12Gh"
  reposLength: 100,
  specificRepos: [
    "banner",
    "text-to-image",
    "air-balloon",
    "character-similarity",
    "image-processing",
    "sentiment-analysis",
  ],
};

const projects = {
  banner: require("../assets/projects/banner.jpg"),
  "sentiment-analysis": require("../assets/projects/sentiment-analysis.png"),
  "air-balloon": require("../assets/projects/air-balloon.png"),
  "image-processing": require("../assets/projects/image-processing.png"),
  "character-similarity": require("../assets/projects/character-similarity.png"),
  "text-to-image": require("../assets/projects/text-to-image.png"),
};

const skills = {
  show: true,
  heading: "Tech Stack I Use",
  hardSkills: [
    { name: "Python", value: 90 },
    { name: "SQL", value: 75 },
    { name: "Data Structures", value: 85 },
    { name: "C/C++", value: 65 },
    { name: "JavaScript", value: 90 },
    { name: "React", value: 65 },
    { name: "HTML/CSS", value: 55 },
    { name: "C#", value: 80 },
  ],
  softSkills: [
    { name: "Goal-Oriented", value: 80 },
    { name: "Collaboration", value: 90 },
    { name: "Positivity", value: 75 },
    { name: "Adaptability", value: 85 },
    { name: "Problem Solving", value: 75 },
    { name: "Empathy", value: 90 },
    { name: "Organization", value: 70 },
    { name: "Creativity", value: 90 },
  ],
};

const getInTouch = {
  show: true,
  heading: "Get In Touch",
  message:
    "Got something interesting to discuss? I would love to hear from you! I always feel like communicating with different people enriches my knowledge about many things. Schedule a coffee time with me or feel free to reach me at",
  email: "c@imranul.dev",
};

const experiences = {
  show: true,
  heading: "Experiences",
  row1: [
    {
      role: "Machine Learning Engineer",
      companylogo: require("../assets/img/alexa.jpg"),
      companyName: "Alexa Translations",
      companyWeb: "https://alexatranslations.com",
      date: "July 2022 – Present",
      description:
        // eslint-disable-next-line no-multi-str
        "I am mainly working on building and improving the core Neural Machine Translation component. This involves doing research \
        and implementing the best architectures for model development, data preparation, augmentation and deployement. I am also engaged in \
        building APIs and microservices and contribuiting in improving performance of the machine learning models.",
    },
    {
      role: "Software Engineer - AI/ML", // Here Add Company Name
      companylogo: require("../assets/img/wd.JPG"),
      companyName: "WellDev Ltd.",
      companyWeb: "https://www.welldev.io",
      date: "March 2021 – September 2022",
      description:
        // eslint-disable-next-line no-multi-str
        "My main focus here was to perform research and development of a centralized NLP module consisting of different tasks which are being used by different applications. \
        This involves working mainly in Python and FastAPI. Additionally, I contributed in Frontend development of a chatbot software (aiaibot.com) \
        and led the development of a Recruitment Management System written in Vue.js."
    },
  ],
  row2: [
    {
      role: "Machine Learning Researcher",
      companylogo: require("../assets/img/ap.png"),
      companyName: "Apurba Technologies Ltd.",
      companyWeb: "http://apurbatech.com",
      date: "June 2020 – December 2020",
      description:
        // eslint-disable-next-line no-multi-str
        "Architected and developed a text recognition system (OCR) from scratch. Built a large number of \
        synthetic data based on different image augmentation and noises in addition to real data for improving real world detection performance. Also deployed the final API to Amazon Web Services (AWS) \
        which is now being used to convert image documents to editable text format.",
    },
    {
      role: "Deep Learning R&D Intern",
      companylogo: require("../assets/img/gaze.jpeg"),
      companyName: "Gaze.ai",
      companyWeb: "https://gaze.ai",
      date: "September 2019 – November 2019",
      description:
        // eslint-disable-next-line no-multi-str
        "I was mainly assigned with the Research and Development of an Anti Face-Spoofing detection system that can distinguish between real face \
        and pictures/videos. This process involved reading the latest research papers and implementing a customized model which works on local face images. I also \
        implemented the model to work with webcams that can perform inference real time.",
    },
  ],
};

export {
  navBar,
  mainBody,
  about,
  repos,
  skills,
  getInTouch,
  experiences,
  icons,
  projects,
};
