/* eslint-disable jsx-a11y/alt-text */
import React from "react";
const divStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};
const Skills = ({ icons }) => {
  return (
    <div
      id="skills"
      className="jumbotron jumbotron-fluid m-0"
      style={{ backgroundColor: "white" }}
    >
      <div className="container container-fluid">
        <div className="d-inline align-self-center">
          <h1 className="display-4 pb-5 text-center">Tech I Use</h1>
          <div className="col d-lg-inline align-self-center">
            <br />
            <div className="row" style={divStyle}>
              <div className="col text-center">
                <img className="icon" src={icons.python} />
              </div>
              <div className="col text-center">
                <img className="icon" src={icons.flask} />
              </div>
              <div className="col text-center">
                <img className="icon" src={icons.javascript} />
              </div>
            </div>
            <br />
            <div className="row" style={divStyle}>
              <div className="col text-center">
                <img className="icon" src={icons.vue} />
              </div>
              <div className="col text-center">
                <img className="icon" src={icons.react} />
              </div>
              <div className="col text-center">
                <img className="icon" src={icons.pytorch} />
              </div>
            </div>
            <br />
            <div className="row" style={divStyle}>
              <div className="col text-center">
                <img className="icon" src={icons.git} />
              </div>
              <div className="col text-center">
                <img className="icon" src={icons.linux} />
              </div>
              <div className="col text-center">
                <img className="icon" src={icons.mysql} />
              </div>
            </div>
            <br />
            <div className="row" style={divStyle}>
              <div className="col text-center">
                <img className="icon" src={icons.java} />
              </div>
              <div className="col text-center">
                <img className="icon" src={icons.spring} />
              </div>
              <div className="col text-center">
                <img className="icon" src={icons.postman} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
