import React from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Skeleton from "react-loading-skeleton";

const ProjectCard = ({ projects, value }) => {
  const {
    name,
    description,
    svn_url,
  } = value;
  return (
    <Col md={6}>
      <Card className="card shadow-lg p-3 mb-5 bg-white rounded">
        <Card.Body>
          <Card.Title as="h5">{name || <Skeleton />} </Card.Title>
          <Card.Text>
            {!description ? "" : description || <Skeleton count={3} />}{" "}
          </Card.Text>
          <img
            className="project-image bg-white mb-3"
            src={projects[name]}
            alt=""
          />
          {svn_url ? (
            <CardButtons projects={projects} svn_url={svn_url} />
          ) : (
            <Skeleton count={2} />
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

const CardButtons = ({ projects, svn_url }) => {
  return (
    <>
      <a
        href={`${svn_url}/archive/refs/heads/master.zip`}
        className="btn btn-outline-secondary mr-3"
      >
        <i className="fab fa-github" /> Clone Project
      </a>
      <a href={svn_url} target=" _blank" className="btn btn-outline-secondary">
        <i className="fab fa-github" /> Repo
      </a>
    </>
  );
};

export default ProjectCard;
