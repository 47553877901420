import React from "react";

import { Col } from "react-bootstrap";
const ExperienceCard = ({ data }) => {
  return (
    <Col lg="6">
      <div className="pb-5 text-center">
        <img
          className="img-company bg-white mb-3"
          src={data.companylogo}
          alt=""
        />
        <p className="lead">
          <strong>{data.companyName}</strong>
          <br />
          <a href={data.companyWeb}>{data.companyWeb}</a>
          <br />
          <strong>{data.role}</strong>
          <br />
          <strong>{data.date}</strong>
          <br />
          <br />
          <p>
            <i className="fas fa-code" /> {data.description}
          </p>
        </p>
      </div>
    </Col>
  );
};

export default ExperienceCard;
